import React, { useMemo } from "react";
import { Toggle } from "@/components/ui/toggle";
import { useTheme } from "@/contexts/Theme";
import { SunIcon, MoonIcon } from "@radix-ui/react-icons";
import { ToggleGroup } from "@/components//ui/toggle-group";
import useTranslate from "@/hooks/useTranslate";
import { useSidebar } from "@/contexts/SidebarContext";

const ThemeToggle: React.FC = () => {
  const { theme, setTheme } = useTheme();
  const t = useTranslate();
  const { isExpanded } = useSidebar();
  const toggleClass = !isExpanded ? "w-6 h-6 p-1" : "w-auto h-8 px-4";

  return (
    <ToggleGroup
      className={`w-full bg-slate-700 transition-all ${isExpanded ? "gap-2 p-2" : "gap-1 p-1"} flex justify-center rounded-lg`}
      type="single">
      <Toggle
        pressed={theme === "light"}
        onPressedChange={() => setTheme("light")}
        className={`transition-none ${toggleClass}`}>
        <SunIcon className={isExpanded ? "mr-2" : ""} />
        {isExpanded && t("theme.light")}
      </Toggle>
      <Toggle
        pressed={theme === "dark"}
        onPressedChange={() => setTheme("dark")}
        className={`transition-none ${toggleClass} data-[state=on]:bg-slate-100 data-[state=on]:text-slate-900`}>
        <MoonIcon className={isExpanded ? "mr-2" : ""} />
        {isExpanded && t("theme.dark")}
      </Toggle>
    </ToggleGroup>
  );
};

export default ThemeToggle;
