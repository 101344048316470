import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import englishTranslations from "./en.json";
import { isDevelopment } from "@/helpers/environment";

const appI18n = i18n.createInstance();

appI18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: isDevelopment,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        app: englishTranslations,
      },
    },
  });

export default appI18n;
