import React from "react";
import Sidebar from "@/components/layout/Sidebar";
import { useSidebar } from "@/contexts/SidebarContext";
import BottomMenu from "@/components/layout/MobileMenu";

import { Outlet } from "react-router-dom";

const Root: React.FC = () => {
  const { isExpanded } = useSidebar();

  return (
    <div className="px-[1%]">
      <div className="grid gap-[1%] transition-all duration-300 ease-in-out sm:grid-cols-1 md:grid-cols-10 lg:grid-cols-12">
        <aside
          className={`hidden md:block ${isExpanded ? "md:col-span-2" : "md:col-span-1"} h-full py-4 transition-all duration-300 ease-in-out [transition-behaviour:allow-discrete]`}>
          <Sidebar />
        </aside>
        <BottomMenu />
        <main
          className={`${isExpanded ? "md:col-span-7 lg:col-span-10" : "md:col-span-9 lg:col-span-11"} scrollbar max-h-dvh overflow-y-auto`}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default React.memo(Root);
