import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "@/translations/i18n";

const useTranslate = () => {
  const { t } = useTranslation("app", { i18n });
  return t;
};

export default useTranslate;
