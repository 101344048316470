import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "@/polyfills";

import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import { routes } from "@/router/routes";
import SuspenseFallback from "@/components/layout/SuspenseFallback";

import "@/main.css";
import { ThemeProvider } from "@/contexts/Theme";

import { SidebarProvider } from "./contexts/SidebarContext";
import { ReactQueryProvider } from "@/contexts/ReactQuery";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { avalancheFuji } from "viem/chains";
import { getAuthToken } from "@dynamic-labs/sdk-react-core";

import "@/translations/i18n";

const router = createBrowserRouter(routes);
const evmNetworks = [
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: 43113,
    chainName: "Avalanche Fuji",
    iconUrls: ["https://app.dynamic.xyz/assets/networks/avax.svg"],
    name: "Avalanche Fuji",
    nativeCurrency: {
      decimals: 18,
      name: "Avax",
      symbol: "AVAX",
    },
    networkId: 43113,
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    vanityName: "Avalanche Fuji",
  },
];

const config = createConfig({
  chains: [avalancheFuji],
  multiInjectedProviderDiscovery: false,
  transports: {
    [avalancheFuji.id]: http(),
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <SidebarProvider>
      <ReactQueryProvider>
        <ThemeProvider>
          <DynamicContextProvider
            settings={{
              environmentId: "d7677a9c-96be-4034-bc77-6ed585651435",
              overrides: { evmNetworks },
              walletConnectors: [EthereumWalletConnectors],
              // walletConnectorExtensions: [EthersExtension],
              events: {
                onAuthSuccess: (args) => {
                  console.log("onAuthSuccess was called", args);
                  const authToken = getAuthToken();
                  console.log("authToken", authToken);
                  //TODO: Call /authenticate on backend, save token to local storage
                },
              },
            }}>
            <WagmiProvider config={config}>
              <Suspense fallback={<SuspenseFallback />}>
                <DynamicWagmiConnector>
                  <RouterProvider router={router} />
                </DynamicWagmiConnector>
              </Suspense>
            </WagmiProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </DynamicContextProvider>
        </ThemeProvider>
      </ReactQueryProvider>
    </SidebarProvider>
  </React.StrictMode>
);
