export const TESTNET_CHAIN_ID = 43113;
export const MAINNET_CHAIN_ID = 43114;

export const TESTNET_USDC_ADDRESS =
  "0x5425890298aed601595a70AB815c96711a31Bc65";
export const MAINNET_USDC_ADDRESS =
  "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";

export const AVAX_DECIMALS = 18;
export const USDC_DECIMALS = 6;
