import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useBiconomyAccount } from "./useBiconomyAccount";
import { getUSDCContractAddress } from "@/helpers/usdc";
import { USDC_ABI } from "@/constants/usdcABI";
import { USDC_DECIMALS } from "@/constants/chain";
import { encodeFunctionData, formatUnits } from "viem";
import { PaymasterMode } from "@biconomy/account";

const useWeb3 = () => {
  const { primaryWallet } = useDynamicContext();
  const { smartAccount } = useBiconomyAccount();

  const getUSDCBalance = async () => {
    if (!primaryWallet) return;

    const publicClient = await primaryWallet?.getPublicClient();
    // console.log(publicClient);

    try {
      const balance = await publicClient.readContract({
        address: getUSDCContractAddress(),
        abi: USDC_ABI,
        functionName: "balanceOf",
        //need to wait for smart account to be available, IF it is an embedded wallet
        args: [
          primaryWallet.connector.isEmbeddedWallet
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (smartAccount as any)?.accountAddress
            : primaryWallet.address,
        ],
      });
      return formatUnits(balance, USDC_DECIMALS);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const approveTokenSpend = async (
    tokenAddress: string,
    spenderAddress: string,
    amount: string
  ) => {
    if (!primaryWallet) return;
    const walletClient = await primaryWallet?.getWalletClient();

    try {
      const balance = await walletClient.writeContract({
        address: tokenAddress,
        account: primaryWallet.address,
        abi: USDC_ABI,
        functionName: "approve",
        args: [spenderAddress, amount],
      });
      return formatUnits(balance, USDC_DECIMALS);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const approveTokenSpendBiconomy = async (
    tokenAddress: string,
    spenderAddress: string,
    amount: string
  ) => {
    if (!smartAccount) return;

    try {
      const txData = encodeFunctionData({
        abi: USDC_ABI,
        functionName: "approve",
        args: [spenderAddress, amount],
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userOpResponse = await (smartAccount as any).sendTransaction(
        {
          to: tokenAddress,
          data: txData,
        },
        {
          paymasterServiceData: { mode: PaymasterMode.SPONSORED },
        }
      );
      console.log("userOpResponse", userOpResponse);
      const { transactionHash } = await userOpResponse.waitForTxHash();
      console.log("transactionHash", transactionHash);
      const userOpReceipt = await userOpResponse.wait();
      if (userOpReceipt.success == "true") {
        console.log("UserOp receipt", userOpReceipt);
        console.log("Transaction receipt", userOpReceipt.receipt);
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  return { getUSDCBalance, approveTokenSpend, approveTokenSpendBiconomy };
};

export default useWeb3;
