import useTranslate from "@/hooks/useTranslate";
import useWeb3 from "@/hooks/useWeb3";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import USDCLight from "@/assets/svg/usdc-light.svg?react";
import USDCDark from "@/assets/svg/usdc-dark.svg?react";
import { useTheme } from "@/contexts/Theme";
import { useSidebar } from "@/contexts/SidebarContext";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { getUSDCContractAddress } from "@/helpers/usdc";
import { useBiconomyAccount } from "@/hooks/useBiconomyAccount";

const TokenBalance = () => {
  const { theme } = useTheme();
  const { isExpanded } = useSidebar();
  const isDark = theme === "dark";

  const [balance, setBalance] = useState<string | undefined>(undefined);
  const isLoggedIn = useIsLoggedIn();

  const { getUSDCBalance, approveTokenSpend, approveTokenSpendBiconomy } =
    useWeb3();

  const { smartAccount } = useBiconomyAccount();

  const t = useTranslate();

  useEffect(() => {
    const callAsync = async () => {
      const balance = await getUSDCBalance();
      setBalance(balance);
    };
    if (!balance) callAsync();
  }, [isLoggedIn]);

  useEffect(() => {
    console.log("my Biconomy smart account", smartAccount);
  }, [smartAccount]);

  if (!isLoggedIn) {
    return null;
  }

  if (!balance) {
    return <Skeleton width="100" height={32} />;
  }

  return (
    <div
      className={`${isDark ? "bg-slate-800 text-slate-400" : "bg-slate-200 text-slate-600"} mt-8 flex p-3 ${isExpanded ? "justify-between" : "flex-col"} w-full items-center rounded-lg`}>
      {isExpanded && <div>{t("menu.balance")}</div>}
      <div
        className={`flex ${isExpanded ? "justify-between" : ""} items-center`}>
        <div className="flex items-center gap-2">
          {!isDark ? <USDCLight /> : <USDCDark />}
        </div>
        <div
          className={`font-bold ${isDark ? "text-white" : "text-gray-900"} ${isExpanded ? "ml-3" : ""}`}>
          &nbsp;{balance}
        </div>
      </div>
      <button
        onClick={() =>
          // approveTokenSpend(
          //   getUSDCContractAddress(),
          //   "0xCa41DE05d164cb3FcbDf69d30123af4807CF228E",
          //   "10"
          // )
          approveTokenSpendBiconomy(
            getUSDCContractAddress(),
            "0xCa41DE05d164cb3FcbDf69d30123af4807CF228E",
            "10"
          )
        }>
        Approve
      </button>
    </div>
  );
};

export default TokenBalance;
