import { useMemo } from "react";
import ThemeToggle from "@/components/layout/ThemeToggle";
import { Separator } from "@/components/ui/separator";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import FullLogo from "@/assets/svg/fullLogo.svg?react";
import ShortLogo from "@/assets/svg/shortLogo.svg?react";
import { useTheme } from "@/contexts/Theme";
import { navItems } from "@/constants/sidebar";
import { NavLink } from "react-router-dom";
import useTranslate from "@/hooks/useTranslate";
import User from "@/components/layout/User";
import TokenBalance from "@/components/layout/TokenBalance";
import { useSidebar } from "@/contexts/SidebarContext";

const Sidebar = () => {
  const { theme } = useTheme();
  const { isExpanded, toggleSidebar } = useSidebar();
  const isDark = useMemo(() => theme === "dark", [theme]);
  const t = useTranslate();

  return (
    <Card
      className={`relative hidden h-full w-full flex-col items-center rounded-2xl bg-background p-6 py-8 transition-all duration-1000 md:flex`}>
      <div
        className={`${isDark ? "text-lightblue" : "text-slate-900"} w-full p-1 ${isExpanded ? "" : "flex justify-center"}`}>
        {isExpanded ? <FullLogo /> : <ShortLogo />}
      </div>
      <Button
        onClick={toggleSidebar}
        className="absolute -right-4 top-8 h-7 w-7 rounded-full bg-slate-700 p-0 text-white hover:bg-slate-800">
        {isExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </Button>
      <TokenBalance />
      <Separator className="my-6" />
      <div className="flex h-full w-full flex-col justify-between">
        <div
          className={`mt-6 flex flex-col gap-1 ${isExpanded ? "" : "items-center"}`}>
          {navItems.map((item) => (
            <NavLink to={item.path} key={item.path}>
              {({ isActive }: { isActive: boolean }) => (
                <Button
                  className={`h-14 ${isActive ? (isDark ? "bg-slate-700" : "bg-slate-200") : "bg-transparent"} hover:bg-slate-${isDark ? "600" : "100"} ${isDark ? "text-white" : "text-slate-700"} ${isExpanded ? "w-full justify-start" : ""}`}>
                  <div className={isExpanded ? "w-1/5" : ""}>{item.icon} </div>
                  {isExpanded && (
                    <div className="w-4/5 pl-2 text-start">
                      {t(item.namePath)}
                    </div>
                  )}
                </Button>
              )}
            </NavLink>
          ))}
        </div>
        <div className="w-full">
          <ThemeToggle />
          <Separator className="mt-4" />
          <User />
        </div>
      </div>
    </Card>
  );
};

export default Sidebar;
