import { NavLink } from "react-router-dom";
import MenuButton from "@/assets/svg/MenuButton.svg?react";
import { useTheme } from "@/contexts/Theme";
import { useMemo } from "react";
import { ArrowLeftRight, Users, Wallet } from "lucide-react";
import BattleIcon from "@/assets/svg/battle.svg?react";

const navItems = [
  {
    namePath: "Trade",
    path: "/trade",
    icon: <ArrowLeftRight size={20} />,
  },
  {
    namePath: "Vs",
    path: "/battles",
    icon: (
      <div className="flex items-center rounded-full px-2 py-1">
        <BattleIcon />
      </div>
    ),
  },
  {
    namePath: "Artists",
    path: "/artists",
    icon: <Users />,
  },
  {
    namePath: "Wallet",
    path: "/wallet",
    icon: <Wallet size={20} />,
  },
];

const BottomMenu = () => {
  const { theme } = useTheme();
  const isDark = useMemo(() => theme === "dark", [theme]);

  return (
    <div className="fixed bottom-4 left-1/2 w-11/12 max-w-96 -translate-x-1/2 transform md:hidden">
      <svg
        viewBox="0 0 377 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-auto w-full drop-shadow-lg">
        <path
          d="M0.75 28C0.75 12.9502 12.9502 0.75 28 0.75H147.579C153.974 0.75 159.599 4.97628 161.379 11.1183C169.253 38.2872 207.747 38.2872 215.621 11.1183C217.401 4.97628 223.026 0.75 229.421 0.75H349C364.05 0.75 376.25 12.9502 376.25 28C376.25 43.0498 364.05 55.25 349 55.25H28C12.9502 55.25 0.75 43.0498 0.75 28Z"
          fill={isDark ? "#1F2937" : "#F8FAFC"}
          stroke={isDark ? "#4B5563" : "#E2E8F0"}
          strokeWidth="1.5"
        />
      </svg>
      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-between px-2">
        {navItems.slice(0, 2).map((item) => (
          <NavButton key={item.path} {...item} />
        ))}
        <div className="w-12"></div> {/* Space for action button */}
        {navItems.slice(2).map((item) => (
          <NavButton key={item.path} {...item} />
        ))}
      </div>

      <button className="text- absolute left-[50%] top-0 -translate-x-1/2 -translate-y-1/2 scale-75 transform rounded-full bg-lightblue p-[0.625rem] outline-4">
        <MenuButton />
      </button>
    </div>
  );
};

interface NavButtonProps {
  namePath: string;
  path: string;
  icon: React.ReactNode;
}

const NavButton: React.FC<NavButtonProps> = ({ namePath, path, icon }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `flex flex-col items-center justify-center p-1 ${
          isActive ? "rounded-3xl bg-slate-700 text-white" : ""
        }`
      }>
      {({ isActive }) => (
        <>
          <div className="xs:scale-100 scale-75">{icon}</div>
          {isActive ? (
            <div className="xs:h-3 flex h-4 w-12 items-center justify-center">
              <div className="h-1 w-1 rounded-full bg-white"></div>
            </div>
          ) : (
            <div className="flex h-4 w-12 items-center justify-center">
              <span className="truncate text-[10px]">{namePath}</span>
            </div>
          )}
        </>
      )}
    </NavLink>
  );
};

export default BottomMenu;
