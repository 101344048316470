import React, { useMemo } from "react";
import AvatarIcon from "@/assets/svg/avatar.svg?react";
import useTranslate from "@/hooks/useTranslate";
import { LogOut, LogIn } from "lucide-react";
import { useSidebar } from "@/contexts/SidebarContext";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";

const User: React.FC = () => {
  const { isExpanded } = useSidebar();
  const t = useTranslate();
  const { user, handleLogOut, setShowAuthFlow } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();

  const resolvedUsername = useMemo(
    () => (isLoggedIn && user ? user.username : "Guest"),
    [isLoggedIn, user]
  );

  return (
    <div className="flex items-center pt-4">
      <AvatarIcon />
      {isExpanded && (
        <div className="pl-4">
          {isLoggedIn && <div className="mb-1">{resolvedUsername}</div>}
          <div className="cursor-pointer text-slate-500">
            {isLoggedIn ? (
              <div
                className="flex items-center gap-2"
                role="presentation"
                onClick={handleLogOut}>
                {t("auth.sign_out")}
                <LogOut size={16} />
              </div>
            ) : (
              <div
                role="presentation"
                className="flex items-center gap-2"
                onClick={() => setShowAuthFlow(true)}>
                {t("auth.sign_in")}
                <LogIn size={16} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(User);
